import React from "react";
import BigImg from '../static/edit1.jpg'
import HeadContent from './Screen'



export default () => {
    return <HeadContent value={
            {
                bigImg: BigImg,
                h1: '圆众',
                h2: '深耕汽车服务业的管理专家',
                btn: '了解更多'
            }
        }/>
}