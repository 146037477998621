import React from 'react'
import img1 from './static/矩形 2@2x (4).jpg'

import img3 from './static/pexels-nataliya-vaitkevich-5412270@2x.png'
import img4 from './static/pexels-nataliya-vaitkevich-5412270备份@2x.png'

import one from '../static/01@2x.png'
import two from '../static/02@2x.png'

export default () => {
    return <div className="service2">
        <div className="home">
            <div className="big-img">
                <img src={img1} alt="" />
            </div>
            <div className="content">
                <h1>企业管理体系<br />
                    构建服务</h1>
            </div>
        </div>

        <div className="s1">
            <div className="item">
                <img src={one} alt="" className="num" />
                <h1>企业管理体系建设</h1>
                <img src={img3} alt="" className="big" />
                <p>圆众可为企业提供企业全面管理体系建设服务，包括企业各部门（如人力资源、运营、行政、财务等部门）构建及职责划分、管理体系构建。</p>
            </div>
            <div className="item">
                <img src={two} alt="" className="num" />
                <h1>企业运营流程的规则规范制定</h1>
                <img src={img4} alt="" className="big" />
                <p>圆众可根据企业管理、运营的情况，为企业制定与之相符的管理制度，帮助企业更好的进行人员及流程管理。</p>
            </div>
        </div>
    </div>
}