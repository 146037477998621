import React from "react";
import Header, { menus } from "./Header";
import Footer from "./Footer";
import { Outlet, BrowserRouter, Routes, Route } from 'react-router-dom'
import './style.less'
import Content1 from './serviceContent1'
import Content2 from './serviceContent2'
import Content3 from './serviceContent3'

export default () => {
    return <div className="pc">
        <BrowserRouter>
            <Header />
            <Routes>
                {menus.map((val, index) => {
                    return <Route key={index} path={val.path} element={val.element} />
                })}
                <Route path={'/content/1'} element={<Content1 />} />
                <Route path={'/content/2'} element={<Content2 />} />
                <Route path={'/content/3'} element={<Content3 />} />
            </Routes>
            <Outlet />
            <Footer />
        </BrowserRouter>
    </div>
}