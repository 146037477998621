import React from 'react'
import img1 from './static/矩形 2@2x (4).jpg'
import img2 from './static/编组@2x (2).png'
import img3 from './static/pexels-nataliya-vaitkevich-5412270@2x.png'
import img4 from './static/pexels-nataliya-vaitkevich-5412270备份@2x.png'

import one from '../static/01@2x.png'
import two from '../static/02@2x.png'
import three from '../static/03@2x.png'

export default () => {
    return <div className="service1">
        <div className="home">
            <div className="big-img">
                <img src={img1} alt="" />
            </div>
            <div className="content">
                <h1>企业财务预算<br />
                    规划制定服务</h1>
            </div>
        </div>
        <div className="s1">
            <div className="item">
                <img src={one} alt="" className="num" />
                <h1>圆众全面预算智能管理系统</h1>
                <img src={img2} alt="" className="big" />
                <h2>帮助企业更好的监测和管理财务状况</h2>
                <p>相较于其它类型的企业，汽车行业由于售后类目繁杂、后端市场细分项众多，财务账目从录入到分析难度都更大。圆众针对以上情况，为车企开发了有针对性的专业智能化财务管理系统，让车企财务化繁为简，帮助企业管理者更好的了解企业财务状况。</p>
            </div>
            <div className="item">
                <img src={two} alt="" className="num" />
                <h1>财务全面分析</h1>
                <img src={img3} alt="" className="big" style={{height: '40vw'}}/>
                <h2>交给专业人员分析，企业经营才能更轻松</h2>
                <p>企业每年至少开展两次全面化的财务分析。从每个细分项着手，分析每项数据变化的原因，提前排除可能存在的潜在风险，制定相关应对方针；同时，为当下无法规避的风险项，制定未来化的应对措施，提高企业抗风险能力。</p>
                <div className="more" onClick={()=>{
                    window.location = `//${window.location.host}/contact`
                }}>了解更多</div>
            </div>
            <div className="item">
                <img src={three} alt="" className="num" /> 
                <h1>企业整体预算规划</h1>
                <img src={img4} alt="" className="big" style={{height: '40vw'}}/>
                <h2>合理管理成本，企业才有生存和竞争能力</h2>
                <p>企业管理是为了经营服务的，而经营是为了创造尽可能大的附加价值来满足企业的发展需求。圆众可帮助企业预估未来的成本支出，同时根据成本，制定相符的盈利目标，让企业未来发展道路更清晰。</p>
                <div className="more" onClick={()=>{
                    window.location = `//${window.location.host}/contact`
                }}>了解更多</div>
            </div>
        </div>
    </div>
}