import React from "react";
import Head from './serviceContent'
import img1 from '../static/编组@2x (2).png'
import img2 from '../static/编组备份@2x.png'

export default () => {
    return <div className="serveice-content-page">
        <Head />

        <div className="service-content-3">
            <h1>企业专属系统、APP定制</h1>
            <h2>根据企业经营需求及管理情况，为企业定制开发专属软件</h2>
            <h2>（包括但不限于财务系统、OA系统、考勤系统、数据管理系统、会员管理系统）。</h2>
            <div className="imgs">
                <img src={img1} alt="" />    
                <img src={img2} alt="" />    
            </div>
        </div>
    </div>
}