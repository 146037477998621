import React from "react";


export default () => {
    return <div className="footer">
        <div className="footer-content">
            <div className="footer-content-item">
                <h3>关于我们</h3>
                <p>为诸多大型销售集团<br /> 提供全面化一对一的咨询服务</p>
            </div>
            <div className="footer-content-item">
                <h3>联系我们</h3>
                <p>service@maruzon.cn   <br />
                    <span style={{whiteSpace: 'nowrap'}}>上海市黄浦区陆家浜路1295号金田商务407室</span>
                </p>
            </div>
            <div className="footer-content-item">
                <h3>免责声明</h3>
                <p>本声明未涉及的问题参见国家有关法律法规，以国家法律法规为准。</p>
            </div>
        </div>

        <p className="back">
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备2021006765号-1</a>  <br />
               <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102007091" target="_blank">沪公网安备31010102007091号</a>
        </p>
    </div>
}