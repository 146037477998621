import React, { useState } from 'react'
import axios from 'axios'



export default () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('')
    return <div className="contact-us-mobile">
        <div className="form">

            <div className="label" style={{ marginRight: 40 }}>姓名</div>
            <input type="text" value={name} onChange={e => {
                // console.log(e.target.value)
                if(e.target.value.length> 100)return
                setName(e.target.value)
            }} style={{ marginRight: 40 }} placeholder="您的姓名" className="input" />
            <div className="label" >邮箱</div>
            <input type="text" value={email} onChange={e => {
                // console.log(e.target.value)
                if(e.target.value.length> 100)return
                setEmail(e.target.value)
            }} placeholder="您的邮箱" className="input" />

            <div className="label">您想说的话</div>
            <textarea name="" id="" value={msg} onChange={e => {
                // console.log(e.target.value)
                if(e.target.value.length> 100)return
                setMsg(e.target.value)
            }} placeholder="说些什么吧……" cols="30" rows="6"></textarea>
            <div className="btn">
                <button onClick={() => {
                    // console.log(name)
                    // console.log(email)
                    // console.log(msg)
                    if (name && email && msg) {
                        axios.post("http://81.69.1.166:8101/createMessage", {
                            // "create_time": new Date(),
                            // "create_user": "maruzon.cn",
                            "info": msg,
                            "mail": email,
                            "name": name,
                            "tel": "",
                            // "update_time": new Date(),
                            // "update_user": "maruzon.cn"
                        }).then(() => {
                            setName('')
                            setEmail('')
                            setMsg('')
                            alert("发送成功")
                        })
                    } else {
                        alert('姓名、邮箱、您想说的话不能为空')
                    }
                }}>发送</button>
            </div>
        </div>
    </div>
}
