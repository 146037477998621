import React from 'react'
import img1 from '../static/编组@2x.png'
import img2 from '../static/编组@2x (1).png'
import {useNavigate} from 'react-router-dom'

export default props => {
    const nav = useNavigate()
    return <div className="home">
            <div className="big-img">
                <img src={props.value.bigImg} alt="" />
            </div>
            <div className="content">
                <h1>{props.value.h1}</h1>
                <h2>{props.value.h2}</h2>
                {/* <p>{props.value.desc}</p> */}
                {props.value.btn && <div className="more" onClick={()=>{
                    nav('/about')
                }}>了解更多</div>}
            </div>
            <div className="func">
                {/* <div className="func-item">
                    <div className="img">
                        <img src={img1} alt="" />
                    </div>
                    <div className="text">企业动态</div>
                </div> */}
                <div className="func-item" onClick={()=>{
                    const link = window.document.createElement('a')
                    link.href = 'https://4s.maruzon.cn/login'
                    link.target = "_blank"
                    link.click()
                }}>
                    <div className="img">
                        <img src={img2} alt="" />
                    </div>
                    <div className="text">财务系统登录</div>
                </div>
            </div>
    </div>
}