import React from "react";
import Head from './serviceContent'
import img1 from '../static/编组 4@2x (1).png'
import img2 from '../static/编组 2@2x (1).png'
import img3 from '../static/编组 3@2x (2).png'

import one from '../static/01@2x.png'
import two from '../static/02@2x.png'
import three from '../static/03@2x.png'

export default () => {
    return <div className="serveice-content-page">
        <Head />
        <div className="service-item">
            <div className="img" style={{ marginTop: '-0.1vw'}}>
                <img src={img1} alt="" />
            </div>
            <div className="content-text" style={{top: '10.83vw', left: '9.25vw'}}>
                <div className="title">
                    <img src={one} alt="" />
                    <h1>圆众全面预算智能管理系统</h1>
                </div>
                <h2>帮助企业更好的监测和管理财务状况</h2>
                <p style={{width: '28.92vw'}}>相较于其它类型的企业，汽车行业由于售后类目繁杂、后端市场细分项众多，财务账目从录入到分析难度都更大。</p>
                <p style={{width: '28.92vw'}}>圆众针对以上情况，为车企开发了有针对性的专业智能化财务管理系统，让车企财务化繁为简，帮助企业管理者更好的了解企业财务状况。</p>
            </div>
        </div>
        <div className="service-item">
            <div className="img" style={{ marginTop: '-8vw'}}>
                <img src={img2} alt="" />
            </div>
            <div className="content-text" style={{top: '8.42vw', right: '8.25vw'}}>
                <div className="title">
                    <img src={two} alt="" />
                    <h1>财务全面分析</h1>
                </div>
                <h2>交给专业人员分析，企业经营才能更轻松</h2>
                <p style={{width: '31vw'}}>企业每年至少开展两次全面化的财务分析。</p>
                <p style={{width: '31vw'}}>从每个细分项着手，分析每项数据变化的原因，提前排除可能存在的潜在风险，制定相关应对方针；</p>
                <p style={{width: '31vw'}}>同时，为当下无法规避的风险项，制定未来化的应对措施，提高企业抗风险能力。</p>
                <div className="more" onClick={()=>{
                    window.location = `//${window.location.host}/contact`
                }}>了解更多</div>
            </div>
        </div>
        <div className="service-item">
            <div className="img" style={{ marginTop: '-8vw'}}>
                <img src={img3} alt="" />
            </div>
            <div className="content-text" style={{top: '9.83vw', left: '9.25vw'}}>
                <div className="title">
                    <img src={three} alt="" />
                    <h1>企业整体预算规划</h1>
                </div>
                <h2>合理管理成本，企业才有生存和竞争能力</h2>
                <p style={{width: '31vw'}}>企业管理是为了经营服务的，而经营是为了创造尽可能大的附加价值来满足企业的发展需求。</p>
                <p style={{width: '31vw'}}>圆众可帮助企业预估未来的成本支出，同时根据成本，制定相符的盈利目标，让企业未来发展道路更清晰。</p>
                <div className="more" onClick={()=>{
                    window.location = `//${window.location.host}/contact`
                }}>了解更多</div>
            </div>
        </div>
    </div>
}