import './App.less';
import { useState, useEffect } from 'react'
import Mobile from './mobile'
import Pc from './pc'

function App() {
  const [type, setType] = useState(window.innerWidth < window.innerHeight ? 'mobile' :'pc')

  window.onresize = e => {
    if (window.innerWidth < window.innerHeight) {
      setType('mobile')
    } else {
      setType('pc')
    }
  }

  return (
    <div className="App">
      {type === 'pc' ? <Pc /> : <Mobile />}
    </div>
  );
}

export default App;
