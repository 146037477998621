import React from "react";
import BigImg from '../static/WechatIMG717.jpeg'

export default () => {
    return <div className="serveice-content-head">
        <div className="big-img">
            <img src={BigImg} alt="" />
            <h1>企业财务预算<br />
                规划制定服务</h1>
        </div>
    </div>
}