import React from "react";
import BigImg from '../static/edit4.jpg'
import img1 from '../static/位图@2x.png'
import img2 from '../static/stock-market@2x.png'
import img3 from '../static/位图@2x (1).png'
import HeadContent from './Screen'
import { useNavigate } from 'react-router-dom'

export default () => {
    const nav = useNavigate()
    return <div className="service">
        <HeadContent value={
            {
                bigImg: BigImg,
                h1: '产品与服务',
                h2: '量身定制的产品与服务，将成为您的得力助手',
            }
        } />
        <div className="service-content">
            <div className="content-item">
                <div className="img">
                    <img src={img1} alt="" />
                </div>
                <p>企业财务预算 <br /> 规划制定服务</p>
                <div className="more" onClick={() => {
                    nav('/content/1')
                }}>了解更多</div>
            </div>
            <div className="content-item">
                <div className="img">
                    <img src={img2} alt="" />
                </div>
                <p>企业管理体系<br />构建服务</p>
                <div className="more" onClick={() => {
                    nav('/content/2')
                }}>了解更多</div>
            </div>
            <div className="content-item">
                <div className="img">
                    <img src={img3} alt="" />
                </div>
                <p>企业管理软件 <br />定制开发服务</p>
                <div className="more" onClick={() => {
                    nav('/content/3')
                }}>了解更多</div>
            </div>
        </div>
    </div>
}