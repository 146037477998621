import React from 'react'
import img1 from './static/矩形 2@2x (6).jpg'
import img2 from './static/编组 2@2x (2).png'
import img3 from './static/编组 2备份@2x.png'
import img4 from './static/编组 2备份 2@2x.png'
import img5 from './static/编组 2备份 3@2x.png'
import img6 from './static/编组 2备份 4@2x.png'
import Msg from './msg'

export default () => {
    return <div className="join">
        <div className="home">
            <div className="big-img">
                <img src={img1} alt="" />
            </div>
            <div className="content">
                <h1>加入我们</h1>
            </div>
        </div>

        <div className="img-item">
            <img src={img2} alt="" />
        </div>
        <div className="img-item">
            <img src={img3} alt="" />
        </div>
        <div className="img-item">
            <img src={img4} alt="" />
        </div>
        <div className="img-item">
            <img src={img5} alt="" />
        </div>
        <div className="img-item">
            <img src={img6} alt="" />
        </div>
        <Msg></Msg>
    </div>
}