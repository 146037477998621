import React from "react";
import BigImg from '../static/edit6.jpg'
import HeadContent from './Screen'
import Msg from './msg'
import card1 from '../static/Card Image@2x.jpg'
import card2 from '../static/Card Image@2x (2).jpg'
import card3 from '../static/Card Image@2x (1).jpg'
import card4 from '../static/Card Image备份@2x.jpg'
import card5 from '../static/Card Image备份 2@2x.jpg'


export default () => {
    return <div className="join">
        <HeadContent value={
            {
                bigImg: BigImg,
                h1: '加入我们',
                h2: '',
                // btn: '了解更多'
            }
        } />

        <div className="join-us">
                <div className="card-item">
                    <div className="img"><img src={card1} alt="" /></div>
                    <h2>新媒体运营</h2>
                    <p>负责品牌自营媒体搭建，独立运营微信公众平台、知乎、小红书和抖音等媒体开展品牌营销工作。</p>
                    <p>对新媒体运营内容相关的短视频、音频进行拍摄、制作、后期剪辑，以及投放。</p>
                </div>
                <div className="card-item">
                    <div className="img"><img src={card2} alt="" /></div>
                    <h2>文案策划</h2>
                    <p>负责本公司品牌在各新媒体平台的运营策划，文案撰写，日常维护分析。</p>
                </div>
                <div className="card-item">
                    <div className="img"><img src={card3} alt="" /></div>
                    <h2>平面设计</h2>
                    <p>负责各类媒体视频图片素材的设计制作。</p>
                    <p>负责产品、包装、线下宣传物料的设计制作。</p>
                </div>
                <div className="card-item">
                    <div className="img"><img src={card4} alt="" /></div>
                    <h2>WEB前端</h2>
                    <p>根据产品经理的要求完成前端设计开发任务。</p>
                    <p>根据业务需求设计组件解决方案。</p>
                </div>

                <div className="card-item">
                    <div className="img"><img src={card5} alt="" /></div>
                    <h2>JAVA/GO后端</h2>
                    <p>根据业务需求设计后端解决方案。</p>
                    <p>根据产品经理的要求完成后端设计开发任务。</p>
                </div>
        </div>
        <Msg />

    </div>
}