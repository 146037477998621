import React from 'react'
import img1 from './static/矩形 2@2x (1).jpg'
import img2 from './static/编组 2@2x.png'
import img3 from './static/编组 3@2x (1).png'
import img4 from './static/编组 4@2x.png'
import one from '../static/01@2x.png'
import two from '../static/02@2x.png'
import three from '../static/03@2x.png'

export default () => {
    return <div className="think">
        <div className="home">
            <div className="big-img">
                <img src={img1} alt="" />
            </div>
            <div className="content">
                <h1>理念</h1>
                <h2>基于现状，为您规划智能化发展之路</h2>
            </div>
        </div>
        <div className="think-item">
            <img src={one} alt="" className="num" />
            <h1>基于市场现状</h1>
            <p>只靠出售车辆就能实现大规模盈利的红利时代已经过去。<br />
                市场的多变性、日益剧增的竞争压力、渠道方的价格限制……<br />
                让许多4S集团，财务盈利日益艰难。</p>
            <img src={img2} alt="" className="big" />
        </div>
        <div className="think-item">
            <img src={two} alt="" className="num" />
            <h1>让企业财务管理智能化</h1>
            <p>规划好每一项支出，以财务数据为基础<br />
                给予日常管理更加智能可靠的指引。</p>
            <img src={img3} alt="" className="big" />
        </div>
        <div className="think-item" style={{marginBottom: '13vw'}}>
            <img src={three} alt="" className="num" />
            <h1>规划未来发展</h1>
            <p>从全局观察多元化的市场走向，多维度信息整合，<br />
                依托于企业的智能数据库分析，规划更优更持续发展之路。</p>
            <img src={img4} alt="" className="big" />
        </div>
    </div>
}