import React from 'react'
import img1 from './static/矩形 2@2x (5).jpg'
import img2 from './static/编组 2@2x (1).png'
import img3 from './static/编组 4@2x (1).png'
import img4 from './static/编组 5@2x.png'
import img5 from '../static/WechatIMG714.jpeg'
import Msg from './msg'

export default () => {
    return <div className="contact">
        <div className="home">
            <div className="big-img">
                <img src={img1} alt="" />
            </div>
            <div className="content">
                <h1>联系我们</h1>
            </div>

        </div>

        <div className="item" style={{ marginTop: '10vw' }}>
            <img src={img2} alt="" className="bg" />
            <div className="text">
                <h1>联系电话</h1>
                <p>021-63453221 <br />
                    法定工作日9:00-18:00</p>
            </div>
        </div>
        <div className="item">
            <img src={img3} alt="" className="bg" />
            <div className="text">
                <h1>联系邮箱</h1>
                <p>service@maruzon.cn</p>
            </div>
        </div>
        <div className="item">
            <img src={img4} alt="" className="bg" />
            <div className="text text-container">
                <div className="text-content">
                    <h1>微信公众号</h1>
                    <p>更多信息咨询 <br />
                        可扫码添加客服微信</p>
                </div>
                <div >
                    <img className="qr" src={img5} alt="" />    
                </div>
            </div>
        </div>
        <Msg />
    </div>
}