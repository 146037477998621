import React from "react";
import BigImg from '../static/edit5.jpg'
import img1 from '../static/编组 4@2x (3).png'
import img2 from '../static/编组 3@2x (3).png'
import img3 from '../static/编组 2@2x (3).png'
import HeadContent from './Screen'
import Msg from './msg'
import img5 from '../static/WechatIMG714.jpeg'

export default () => {
    return <div className="contact">
        <HeadContent value={
            {
                bigImg: BigImg,
                h1: '联系我们',
                h2: '',
                // btn: '了解更多'
            }
        } />
        <div className="imgs-content">
            <div className="imgs-content-item">
                <img src={img1} alt="" />
                <div className="text">
                    <h1>联系电话</h1>
                    <p style={{marginTop: '7.5rem'}}>021-63453221 <br />
                        法定工作日9:00-18:00</p>
                </div>
            </div>
            <div className="imgs-content-item" style={{marginLeft: '-1.5rem'}}>
                <img src={img2} alt="" />
                <div className="text">
                    <h1>联系邮箱</h1>
                    <p style={{marginTop: '9.3rem'}}>service@maruzon.cn</p>
                </div>
            </div>
            <div className="imgs-content-item" style={{marginLeft: '-1.5rem'}}>
                <img src={img3} alt="" />
                <div className="text">
                    <h1>微信公众号</h1>
                    <div className="qr-code">
                        <img src={img5} alt="" />
                    </div>
                    <p>更多信息咨询 <br />
                        可扫码添加客服微信@maruzon.cn</p>
                </div>
            </div>
        </div>
        <Msg />
    </div>
}

