import React from "react";
import BigImg from '../static/edit2.jpg'
import HeadContent from './Screen'
import one from '../static/01@2x.png'
import two from '../static/02@2x.png'
import three from '../static/03@2x.png'
import img1 from '../static/编组 2@2x.png'
import img2 from '../static/编组 3@2x (1).png'
import img3 from '../static/编组 4@2x.png'



export default () => {
    return <div className="think">
        <HeadContent value={
            {
                bigImg: BigImg,
                h1: '理念',
                h2: '基于现状，为您规划智能化发展之路',
            }
        } />
        <div className="think-item" style={{ height: '40rem' }}>
            <div className="think-content">
                <div className="num"><img src={one} alt="" /></div>
                <h1>基于市场现状</h1>
                <p>只靠出售车辆就能实现大规模盈利的红利时代已经过去。<br />
                    市场的多变性、日益剧增的竞争压力、渠道方的价格限制……<br />
                    让许多4S集团，财务盈利日益艰难。</p>
            </div>
            <div className="think-big-img" style={{ top: '8rem', right: 0 }} >
                <img src={img1} alt="" style={{
                    width: '46.5rem', height: '28.08rem',
                }} />
            </div>

        </div>
        <div className="think-item" style={{ height: '40rem' }}>
            <div className="think-content" style={{ float: "right" }}>
                <div className="num"><img src={two} alt="" /></div>
                <h1>让企业财务管理智能化</h1>
                <p>规划好每一项支出，以财务数据为基础<br />
                    给予日常管理更加智能可靠的指引。</p>
            </div>
            <div className="think-big-img" style={{ top: '5rem', left: 0 }} >
                <img src={img2} alt="" style={{
                    width: '51.33rem', height: '33.42rem',
                }} />
            </div>
        </div>

        <div className="think-item" style={{ height: '40rem' }}>
            <div className="think-content">
                <div className="num"><img src={three} alt="" /></div>
                <h1>规划未来发展</h1>
                <p style={{width: '23.25rem'}}>从全局观察多元化的市场走向，多维度信息整合，<br />
                    依托于企业的智能数据库分析，规划更优更持续发展之路。</p>
            </div>
            <div className="think-big-img" style={{ top: '2.25rem', right: 0 }} >
                <img src={img3} alt="" style={{
                    width: '48.75rem', height: '32.83rem',
                }} />
            </div>

        </div>
    </div>
}