import React from "react";
import Head from './serviceContent'
import img1 from '../static/编组 4@2x (2).png'
import img2 from '../static/编组 2@2x (2).png'

import one from '../static/01@2x.png'
import two from '../static/02@2x.png'

export default () => {
    return <div className="serveice-content-page">
        <Head />
        <div className="service-item">
            <div className="img" style={{ marginTop: '0vw'}}>
                <img src={img1} alt="" style={{height: '60vw'}}/>
            </div>
            <div className="content-text" style={{top: '10.83vw', left: '9.25vw'}}>
                <div className="title">
                    <img src={one} alt="" />
                    <h1>企业管理体系建设</h1>
                </div>
                {/* <h2>帮助企业更好的监测和管理财务状况</h2> */}
                <p style={{width: '28.92vw'}}>圆众可为企业提供企业全面管理体系建设服务，包括企业各部门（如人力资源、运营、行政、财务等部门）构建及职责划分、管理体系构建。</p>
            </div>
        </div>
        <div className="service-item">
            <div className="img" style={{ marginTop: '-8vw'}}>
                <img src={img2} alt=""  style={{height: '50vw'}}/>
            </div>
            <div className="content-text" style={{top: '8.42vw', right: '8.25vw'}}>
                <div className="title">
                    <img src={two} alt="" />
                    <h1>企业运营流程的规则规范制定</h1>
                </div>
                <p style={{width: '31vw'}}>圆众可根据企业管理、运营的情况，为企业制定与之相符的管理制度，帮助企业更好的进行人员及流程管理。</p>
            </div>
        </div>
    </div>
}