import React from "react";
import { BrowserRouter, Routes, Route, } from 'react-router-dom'
import './style.less'
import Header, { menus } from './header'
import Content1 from '../mobile/service1'
import Content2 from '../mobile/service2'
import Content3 from '../mobile/service3'
import Footer from './footer'

export default () => {
    return <div className="mobile">
        <BrowserRouter>
            <Header></Header>
            <Routes>
                {menus.map((val, index) => {
                    return <Route key={index} path={val.path} element={val.element} />
                })}
                <Route path={'/content/1'} element={<Content1 />} />
                <Route path={'/content/2'} element={<Content2 />} />
                <Route path={'/content/3'} element={<Content3 />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    </div>
}