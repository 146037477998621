import React from 'react'
import img1 from './static/矩形 2@2x (4).jpg'
import img2 from './static/编组@2x (3).png'
import img3 from './static/编组备份@2x.png'


export default () => {
    return <div className="service3">
        <div className="home">
            <div className="big-img">
                <img src={img1} alt="" />
            </div>
            <div className="content">
                <h1>企业管理软件 <br />
定制开发服务</h1>
            </div>
        </div>
        <div className="s3">
            <h1>企业专属系统、APP定制</h1>

            <p>根据企业经营需求及管理情况，为企业定制开发专属软件<br />
                （包括但不限于财务系统、OA系统、考勤系统、数据管理系统、会员管理系统）。</p>
            <img src={img2} alt="" />
            <img src={img3} alt="" />
        </div>

    </div>
}