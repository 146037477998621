import React from 'react'
import img1 from './static/矩形 2@2x (3).jpg'
import img2 from './static/位图@2x.png'
import img3 from './static/stock-market@2x.png'
import img4 from './static/位图@2x (1).png'
import Right from './static/路径@2x.png'
import { useNavigate, useLocation } from 'react-router-dom'

export default () => {
    const nav = useNavigate()
    return <div className="service">
        <div className="home">
            <div className="big-img">
                <img src={img1} alt="" />
            </div>
            <div className="content">
                <h1>产品与服务</h1>
                <h2>量身定制的产品与服务<br />
                    将成为您的得力助手</h2>
            </div>
        </div>
        <div className="service-content">
            <div className="service-item" onClick={() => {
                window.location = `//${window.location.host}/content/1`
            }}>
                <div className="img">
                    <img src={img2} alt="" />
                </div>
                <h1>企业财务预算<br />
                    规划制定服务</h1>
                <div className="right">
                    <img src={Right} alt="" />
                </div>
            </div>
            <div className="service-item" onClick={() => {
                window.location = `//${window.location.host}/content/2`
            }}>
                <div className="img">
                    <img src={img3} alt="" />
                </div>
                <h1>企业管理体系<br />
                    构建服务</h1>
                <div className="right">
                    <img src={Right} alt="" />
                </div>
            </div>
            <div className="service-item" onClick={() => {
                window.location = `//${window.location.host}/content/3`
            }}>
                <div className="img">
                    <img src={img4} alt="" />
                </div>
                <h1>企业管理软件<br />
                    定制开发服务</h1>
                <div className="right">
                    <img src={Right} alt="" />
                </div>
            </div>
        </div>
    </div>
}