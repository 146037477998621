import React from 'react'
import img1 from './static/矩形 2@2x.jpg'
import { useNavigate } from 'react-router-dom'

export default () => {
    const nav = useNavigate()

    return <div className="home">
        <div className="big-img">
            <img src={img1} alt="" />
        </div>
        <div className="content">
            <h1>圆众</h1>
            <h2>深耕汽车服务业的管理专家</h2>
            <div className='more' onClick={() => {
                nav('/about')
            }}>了解更多</div>
        </div>
    </div>
}