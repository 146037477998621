import React from 'react'
import img1 from './static/矩形 2@2x (2).jpg'
import img2 from './static/4380@2x.png'


export default () => {
    return <div className="about">
        <div className="home">
            <div className="big-img">
                <img src={img1} alt="" />
            </div>
            <div className="content">
                <h1>关于圆众</h1>
                <h2>您的专属「指路灯」<br />
                    为您的企业保驾护航</h2>
            </div>
        </div>
        <div className="about-content">
            <h1>指明企业发展路 <br />
                护航企业稳前行<br />
                为4S集团提供全面化财务经营管理方案</h1>
            <img src={img2} alt="" />
            <p>圆众是一家拥有30年以上汽车行业管理经验，致力于为汽车行业客户提供有针对性、专业度高的企业财务管理服务；</p>
            <p>基于行业管理经验及数据库信息整合、配套软件开发、数字化信息转换等能力，圆众能为客户提供更加全面的解决方案；</p>
            <p>为更好的服务汽车行业客户，我们已将大量行业相关渠道整合优化，致力于为客户提供更多市场选择；</p>
            <p>为进一步优化客户使用体验，圆众现已研发出功能强大的汽车企业财务智能管理通用系统，为企业提供半自动化管理服务。</p>
        </div>
    </div>
}