import React from "react";
import Logo from '../static/编组 3@2x.png';
import Home from './home'
import Think from './think'
import About from './about'
import Service from './service'
import Contact from './contact'
import Join from './join'
import { useNavigate, useLocation } from 'react-router-dom'

export const menus = [
    {
        name: '首页',
        path: '/',
        element: <Home />
    },
    {
        name: '理念',
        path: '/think',
        element: <Think />
    },
    {
        name: '关于圆众',
        path: '/about',
        element: <About />
    },
    {
        name: '产品与服务',
        path: '/service',
        element: <Service />
    },
    {
        name: '联系我们',
        path: '/contact',
        element: <Contact />
    },
    {
        name: '加入我们',
        path: '/join',
        element: <Join />
    },

]

export default () => {

    const nav = useNavigate()
    const loc = useLocation()

    return <div className="header">
        <div className="logo">
            <img src={Logo} alt="" />
        </div>
        <div className="menu">
            {menus.map((val, index) => {
                return <div className={`menu-item ${val.path === loc.pathname ? 'menu-item-active' : ''}`} key={index} onClick={() => {
                    nav(val.path)
                }}>{val.name}</div>
            })}
        </div>
    </div>
}